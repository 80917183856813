import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { navData } from "../lib/navData";
import styles from "./sidebar.module.css";
import logo from "../images/logo.png";

export const Sidenav = ({ open, toggleOpen }) => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      {/* Logo - Clickable to Home */}
      <div 
        className={styles.logoContainer} 
        onClick={() => navigate("/")} 
      >
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Sidebar Toggle Button */}
      <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
      </button>

      {/* Navigation Links (Centered) */}
      <div className={styles.sidenavlink}>
        {navData.map((item) => (
          <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
            {open && <span className={styles.linkText}>{item.text}</span>}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
