import React from "react";
import { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const YoutubeCaption = (props) => {
  const player = props.player;
  const response = props.data.apiResponse;
  const { setCurrentSubtitleIndex, currentSubtitleIndex } = props;
  const [contentList, setContentList] = useState([{ content: "" }]);
  const [activeTab, setActiveTab] = useState("youtube-caption");

  useEffect(() => {
    // console.log("useEffect executed");
    // Get the elements with the "my-class" class name
    const elements = document.getElementsByClassName("removeNode");

    // Loop through the elements and add the event listener to each one
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", handleServiceRemove);
    }

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", handleServiceRemove);
      }
    };
  }, [contentList, player]);

  const handleTabClick = (e) => {
    e.preventDefault();
    setActiveTab(e.target.href.split("#")[1]);
  };

  const handleContentAdd = (e) => {
    e.preventDefault();
    const parent = e.target.closest("li").querySelector(".subtitle");
    const html = parent.innerHTML;
    setContentList([...contentList, { content: html }]);
  };

  const handleServiceRemove = (e) => {
    const list = [...contentList];
    const parent = e.target.closest("li");
    parent.remove();
    setContentList(list);
  };

  const handleSeekTo = (e) => {
    e.preventDefault();
    const index = parseFloat(e.target.dataset.index);
    const timestamp = parseFloat(e.target.dataset.timestamp);
    if (player && typeof player.seekTo === "function") {
      player.seekTo(timestamp);
    }
    setCurrentSubtitleIndex(index);
  };

  var htmlRender = [];
  var contentRender = [];
  var savebtn = document.getElementById("save-btn");

  if (response !== "" && response !== undefined && response !== "Not Support") {
    response.map((item, index) => {
      const startTime = parseFloat(item.start);
      const duration = parseFloat(item.dur);
      const endTime = startTime + duration;

      let minutes = (startTime - (startTime % 60)) / 60;
      let seconds = (startTime % 60).toFixed(0);
      const subtitleClasses = ["subBox"];
      if (index === currentSubtitleIndex) {
        // console.log("start:", index, "cur", currentSubtitleIndex);
        subtitleClasses.push("highlight");
      }

      htmlRender.push(
        <li key={index} className="">
          <div className={subtitleClasses.join(" ")}>
            <div className="subItem d-flex row w-100 align-items-center m-0">
              <div className="col-2 timeBox p-0 text-center">
                <span>
                  <a
                    href="#"
                    className="seekTo text-white text-decoration-none"
                    data-timestamp={item.start}
                    data-index={index}
                    onClick={handleSeekTo}
                  >
                    {minutes}:{seconds.padStart(2, "0")}
                  </a>
                </span>
              </div>
              <div className="col-10 subtitleBox d-flex justify-content-between">
                <p className="subtitle">{item.text}</p>
                <a href="#" className="createNode" onClick={handleContentAdd}>
                  <FontAwesomeIcon icon={faCirclePlus} />
                </a>
              </div>
            </div>
          </div>
        </li>
      );
    });
    savebtn.classList.remove("d-none");
  } else {
    htmlRender.push(
      <li className="d-flex">
        <p className="subtitle">
          The Video source is not supported by closed captioning
        </p>
      </li>
    );
  }

  contentList.map((singleContent, index) =>
    contentRender.push(
      <li key={index}>
        <div className="subItem d-flex row align-items-center">
          <div className="col-12 subtitleBox d-flex justify-content-between">
            <p className="subtitle subtitle-text">{singleContent.content}</p>
            <a href="#" className="removeNode">
              <FontAwesomeIcon icon={faTrash} />
            </a>
          </div>
        </div>
      </li>
    )
  );

  return (
    <Fragment>
      <div className="youtube-contents d-none" id="youtube-contents">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "youtube-caption" ? "active" : ""
              }`}
              onClick={handleTabClick}
              href="#youtube-caption"
            >
              YouTube Caption
            </a>
          </li>
          <li className="nav-item border-custom">
            <a
              className={`nav-link ${
                activeTab === "youtube-storage" ? "active" : ""
              }`}
              onClick={handleTabClick}
              href="#youtube-storage"
            >
              YouTube Storage
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="youtube-caption"
            className={`tab-pane fade ${
              activeTab === "youtube-caption" ? "show active" : ""
            }`}
          >
            <div className="row">
              <div className="col-lg-12 col-12">
                <div id="youtube-caption" className="youtube-caption">
                  {htmlRender}
                </div>
              </div>
            </div>
          </div>
          <div
            id="youtube-storage"
            className={`tab-pane fade ${
              activeTab === "youtube-storage" ? "show active" : ""
            }`}
          >
            <div className="row">
              <div className="col-lg-12 col-12">
                <div id="youtube-storage" className="youtube-storage">
                  <div
                    className="youtube-storage-output text-left"
                    id="youtube-storage-output"
                  >
                    {contentRender}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default YoutubeCaption;
