import React, { Fragment, useState } from "react";
import Axios from "axios";
import Button from "react-bootstrap/Button";
import Logo from "../../../images/logo.png";
import { traverse } from "../../../components/domUtils";
/**
 * Renders a button which, when selected, will open a popup for login
 */
const SaveButton = () => {
  /**
   * Renders information about the signed-in user or a button to retrieve data about the user
   */
  const loggedInUser = localStorage.getItem("authenticated");
  const user = JSON.parse(localStorage.getItem("userInfo"));
  // const [elements, setElements] = useState([]);
  const handleSave = () => {
    const saveBtn = document.getElementById("save-btn");
    const html = document.getElementById("youtube-storage-output").innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');  
    const elements = traverse(doc.documentElement);
    const jsonData = JSON.stringify(elements);
    saveBtn.innerHTML = "Processing...";
    saveBtn.disabled = true;
    Axios.post(`${window.location.origin}/notes/create`, {
      uid: user.id,
      content: jsonData,
    })
      .then((res) => {
        alert(res.data.message);
        saveBtn.innerHTML = "Save Note";
        saveBtn.disabled = false;
      })
      .catch((error) => {
        alert(error);
        saveBtn.innerHTML = "Save Note";
        saveBtn.disabled = false;
      });
  };

  // const traverse = (node) => {
  //   const element = {};
  //   if (node.nodeName === "P") {
  //     element.tag = "p";
  //     let text = "";
  //     let href = "";
  //     node.childNodes.forEach((childNode) => {
  //       if (childNode.nodeName === "A") {
  //         href = childNode.getAttribute("href") || "";
  //         traverse(childNode);
  //       } else if (childNode.nodeName === "#text") {
  //         text += childNode.textContent;
  //       }
  //     });
  //     element.text = text.trim();
  //     element.href = href;
  //   } else if (node.nodeName === "IMG") {
  //     element.tag = "img";
  //     element.src = node.getAttribute("src") || "";
  //     element.alt = node.getAttribute("alt") || "";
  //   }
  //   if (element.tag) {
  //     elements.push(element);
  //   }
  //   if (node.childNodes) {
  //     node.childNodes.forEach(traverse);
  //   }
  // };

  return (
    <Fragment>
      <div className="loader d-none" id="loader">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <div className="container m-auto text-white">
        {loggedInUser ? (
          <Button
            variant="secondary"
            id="save-btn"
            className="ml-auto d-none custom-btn"
            onClick={() => handleSave()}
          >
            SAVE
          </Button>
        ) : (
          <Button
            variant="secondary"
            id="save-btn"
            className="ml-auto d-none"
            onClick={() => handleSave()}
          >
            Sign in to Save Note!
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default SaveButton;
