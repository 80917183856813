import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import styles from "../../components/sidebar.module.css";

const Dashboard = ({ open }) => {
  const loggedInUser = localStorage.getItem("authenticated");
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));

  if (!loggedInUser) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div>
        <div className="container text-white user-profile">
          <h2>Welcome to your Profile</h2>
          <p>User ID: {userDetails.id}</p>
          <p>User Email: {userDetails.email}</p>
        </div>
      </div>
    );
  }
};

export default Dashboard;
