import React from "react";
import { Fragment } from "react";
import Axios from "axios";
import YoutubeEmbed from "./YoutubeEmbed";
import YoutubeCaption, { handleTimeUpdate } from "./YoutubeCaption";
import ButtonContainer from "./ButtonContainer";
import logo from "../../../images/logo.png";
import { Auth } from "../../../components/Auth"; // Add this import

class YoutubeForm extends React.Component {
  constructor(props) {
    super(props);
    //在組件內的state設定一個nameVal屬性，並設值為“預設姓名”
    this.state = {
      youtubeLink: "",
      apiResponse: "",
      assemblyResponse: "",
      videoId: "",
      player: null,
      currentSubtitleIndex: null,
    };
    this.changeState = this.changeState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onPlayerReady = (player) => {
    this.setState({
      player: player,
    });
  };
  setCurrentSubtitleIndex = (index) => {
    this.setState({ currentSubtitleIndex: index });
  };
  changeState(event) {
    //使用setState將值寫到nameVal中
    this.setState({ youtubeLink: event.target.value });
  }
  handleSubmit(event, link) {
    event.preventDefault();
    link = document.getElementById("youtubeLink").value;
    var youtubeId = youtube_parser(link);
    this.setState({ videoId: youtubeId });
    const youtubeEmbed = document.getElementById("youtubeEmbed");
    const videoContainer = document.getElementById("video-container");
    const searchContainer = document.getElementById("search-container");
    const resultContainer = document.getElementById("result-container");
    const youtubeStorageOutput = document.getElementById(
      "youtube-storage-output"
    );
    const youtubeContents = document.getElementById("youtube-contents");
    const captureButton = document.getElementById("cit");
    const donateButton = document.getElementById("donate");

    youtubeEmbed.src = `https://www.youtube.com/embed/${youtubeId}??origin=localhost:3000`;
    videoContainer.classList.toggle("d-none", false);
    youtubeStorageOutput.innerHTML = "";
    captureButton.classList.toggle("d-none", false);
    donateButton.classList.toggle("d-none", false);
    youtubeContents.classList.toggle("d-none", false);
    searchContainer.classList.toggle("d-none", true);
    resultContainer.classList.toggle("d-none", false);
    var x = document.getElementsByClassName("previewSection");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "block";
    }
    const getSubtitle = () => {
      const url = `${window.location.origin}/getSubtitles/${youtubeId}`;
      Axios.get(url)
        .then((response) => {
          this.setState({ apiResponse: response.data });
          // const assemblyUrl = `${window.location.origin}/summarize`;
          // const data = {
          //   audioUrl: `https://www.youtube.com/watch?v=${youtubeId}`,
          // };
          // Axios.post(assemblyUrl, data)
          //   .then((response) => {
          //     console.log(response.data);
          //     this.setState({ assemblyResponse: response.data });
          //     // do something with the summarized data
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getSubtitle();
  }

  render() {
    const { loggedInUser } = this.props;
    return (
      <Fragment>
        <div className="container" id="search-container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center mb-5">
              <img src={logo} alt="logo" className="logo" />
              <p className="px-4 fs-42 text-green m-0">Alpha-Internal</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-12  m-auto">
              <p className="text-white fs-20 text-center">
                ALPHAINTERNAL is an online YouTube Subtitles downloader /
                editor. <br></br>offers a free direct solution to those who are
                looking to download any video subtitle in TXT format on YouTube.
                It’ll save you time when you convert, edit, and work on the
                video text output.
              </p>
            </div>
          </div>
          <div className="row  my-4">
            <div className="col-md-8 col-12 m-auto">
              <form
                className="form-inline d-flex align-items-center"
                onSubmit={this.handleSubmit}
                id="youtubeForm"
              >
                <input
                  className="form-control"
                  type="text"
                  id="youtubeLink"
                  name="youtubeLink"
                  value={this.state.youtubeLink}
                  onChange={this.changeState}
                />
                <button
                  type="submit"
                  className="btn offset-1 fs-18 border rounded text-white text-uppercase px-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-8 col-12">
              <div className="row  justify-content-center align-items-center">
                <div className="col-md-4 col-12 text-center">
                  <a
                    href="https://www.buymeacoffee.com/alphainternal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png"
                      alt="Buy Me A Coffee"
                      className="donation"
                      id="donate"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-12 text-center">
                  <Auth loggedInUser={loggedInUser} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="result-wrapper d-none" id="result-container">
          <div
            className="row d-dlex align-items-top result"
          >
            <div
              className="col-12 d-flex justify-content-center align-items-center mb-1"
              style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate clickability
              onClick={() => (window.location.href = "/")} // Redirect on click
            >
              <img src={logo} alt="logo" className="logo" />
              <p className="px-4 fs-42 text-green m-0">Alpha-Internal</p>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12 border-custom">
                <YoutubeEmbed
                  data={this.state}
                  onPlayerReady={this.onPlayerReady}
                  setCurrentSubtitleIndex={this.setCurrentSubtitleIndex}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-12 border-custom">
                <YoutubeCaption
                  data={this.state}
                  player={this.state.player}
                  setCurrentSubtitleIndex={this.setCurrentSubtitleIndex}
                  currentSubtitleIndex={this.state.currentSubtitleIndex}
                />
              </div>
            </div>
            <ButtonContainer data={this.state} player={this.state.player} />
          </div>
        </div>
      </Fragment>
    );
  }
}

function youtube_parser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export default YoutubeForm;
